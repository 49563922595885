import React, { ReactNode } from "react";

interface ControllerContainerProps {
  children: ReactNode;
}

function ControllerContainer({ children }: ControllerContainerProps) {
  return <div className="mb-20 flex gap-4 justify-between h-11">
    {children}
  </div>;
}

export default ControllerContainer;
