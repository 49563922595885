import React from "react";
import { Condo } from "../../contexts/CondoContext";

interface CondoItemProps {
  value: Condo;
}

function CondoItem({ value }: CondoItemProps) {
  const { name, id, CondoPhotos } = value;

  return (
    <div className="grid grid-cols-12 gap-10 w-full justify-start items-center">
      <div className="col-span-3">{name}</div>
      <div className="col-span-3">
        <span>{id}</span>
      </div>
      <div className="col-span-2">
        <span>รูปที่รอการยืนยัน: </span>
        <span className="text-yellow-500">{CondoPhotos.length}</span>
      </div>
    </div>
  );
}

export default CondoItem;
