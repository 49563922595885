import axios from "../config/axios";
import { Order, UpdateCondo } from "../contexts/CondoContext";

export const getCondoApproval = () => {
  return axios.get("/admin/condo/approval");
};

export const searchCondo = (text: string) => {
  return axios.get(`/admin/condolist?name=${text}`);
};

export const createCondo = () => {
  return axios.post("/admin/condo");
};

export const getDetail = (condoId: string) => {
  return axios.get(`/admin/condo/${condoId}`);
};

export const updateCondo = (condoId: string, inputs: UpdateCondo) => {
  return axios.put(`/admin/condo/${condoId}`, inputs);
};

export const uploadCondoImages = (condoId: string, formData: FormData) => {
  return axios.post(`/admin/condo/${condoId}`, formData);
};

export const updateImageOrder = (condoId: string, newOrder: Order[]) => {
  return axios.patch(`/admin/condo/${condoId}/order`, {
    photoOrder: newOrder,
  });
};

export const getCondoWithoutGooglePlaceId = () => {
  return axios.get("/admin/condo/google");
};

export const updateCondoPhotoApproval = (imageId: number) => {
  return axios.patch(`/admin/condo/image/${imageId}/approve`);
};

export const deleteCondoPhoto = (imageId: number) => {
  return axios.delete(`/admin/condo/image/${imageId}`);
};

export const updateAdsExpiry = (condoId: string, adsExpiry: Date) => {
  return axios.patch(`/admin/condo/${condoId}/adsExpiry`, { adsExpiry });
};
