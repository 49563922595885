import React from "react";

interface RoomDocItemProps extends React.HTMLProps<HTMLAnchorElement> {
  order: number;
}

function RoomDocItem({ order, ...props }: RoomDocItemProps) {
  return (
    <div>
      <a target="_blank" {...props}>
        - เอกสารหมายเลข {order}
      </a>
    </div>
  );
}

export default RoomDocItem;
