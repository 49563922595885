import React, { InputHTMLAttributes, useEffect, useRef } from "react";
import { useCondo } from "../../contexts/CondoContext";

function GoogleAutoComplete({
  ...props
}: InputHTMLAttributes<HTMLInputElement>) {
  const ref = useRef<any>(null);

  const { condoDetail, handleGooglePlaceSelect, handleOnChange } = useCondo();

  const options = {
    componentRestrictions: {
      country: "th",
    },
  };
  useEffect(() => {
    const autocomplete = new google.maps.places.Autocomplete(
      ref.current,
      options
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        window.alert("ไม่มีข้อมูลสำหรับ: '" + place.name + "'");
        return;
      }

      handleGooglePlaceSelect(place);

      handleOnChange({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        addressTh: place.formatted_address,
        address: place.formatted_address,
        name: place.name,
        googlePlaceId: place.place_id,
      });
    });
  }, []);

  // useEffect(() => {
  //   const options = {
  //     language: "en",
  //     componentRestrictions: {
  //       country: "th",
  //     },
  //   };

  //   const autocomplete = new google.maps.places.Autocomplete(
  //     ref.current,
  //     options
  //   );

  //   autocomplete.addListener("place_changed", () => {
  //     const place = autocomplete.getPlace();
  //     if (!place.geometry || !place.geometry.location) {
  //       window.alert("ไม่มีข้อมูลสำหรับ: '" + place.name + "'");
  //       return;
  //     }

  //     console.log({ place });

  //     handleOnChange({
  //       address: place.formatted_address,
  //     });
  //   });
  // }, [condoDetail]);

  return (
    <input
      {...props}
      className={props.className}
      ref={ref}
      type="text"
      placeholder="Enter location"
    />
  );
}

export default GoogleAutoComplete;
