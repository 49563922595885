import React from "react";
import Button from "../../components/common/Button";
import ControllerContainer from "../../components/common/ControllerContainer";
import Item from "../../components/common/Item";
import ItemContainer from "../../components/common/ItemContainer";
import Search from "../../components/common/Search";
import Spinner from "../../components/common/Spinner";
import Topic from "../../components/common/Topic";
import ReportItem from "../../components/report/ReportItem";
import { useReport } from "../../contexts/ReportContext";

function ReportListPage() {
  const { reports, loading, getReadReport, getUnreadReport } = useReport();

  return (
    <>
      {reports && (
        <div>
          <Topic topic="รายการปัญหาที่แจ้งเข้ามา" remaining={reports.length} />
          <ControllerContainer>
            <Search className="search-box invisible " />
            <div className="flex gap-4">
              <Button
                text="ยังไม่อ่าน"
                className="button-rp-admin bg-red-500 text-white"
                onClick={() => getUnreadReport()}
              />
              <Button
                text="อ่านแล้ว"
                className="button-rp-admin bg-green-500 text-white"
                onClick={() => getReadReport()}
              />
            </div>
          </ControllerContainer>
          <ItemContainer>
            {reports &&
              reports.map((el) => (
                <Item active={false} key={el.id} id={el.id + ""}>
                  <ReportItem report={el} />
                </Item>
              ))}
          </ItemContainer>
        </div>
      )}
      <Spinner loading={loading} />
    </>
  );
}

export default ReportListPage;
