import React, { useEffect, useState } from "react";
import Button from "../../components/common/Button";
import ControllerContainer from "../../components/common/ControllerContainer";
import Item from "../../components/common/Item";
import ItemContainer from "../../components/common/ItemContainer";
import Search from "../../components/common/Search";
import Spinner from "../../components/common/Spinner";
import Topic from "../../components/common/Topic";
import RoomItem from "../../components/room/RoomItem";
import { useRoom } from "../../contexts/RoomContext";

function RoomListPage() {
  const { rooms, fetchRoomForApproval, loading, searchRoom } = useRoom();

  useEffect(() => {
    fetchRoomForApproval();
  }, []);

  return (
    <>
      {rooms && (
        <div>
          <Topic
            topic="รายชื่อห้อง ที่รอการยืนยัน"
            remaining={rooms ? rooms.length : 0}
          />
          <ControllerContainer>
            <Search
              className="search-box"
              onChange={(e: any) => searchRoom(e.target.value)}
            />
            <div className="flex gap-4">
              <Button
                text="รอการยืนยัน"
                className="button-rp-admin text-red-500"
                onClick={() => fetchRoomForApproval()}
              />
            </div>
          </ControllerContainer>
          <ItemContainer>
            {rooms
              ? rooms.map((el) => (
                  <Item active={true} id={el.id}>
                    <RoomItem room={el} />
                  </Item>
                ))
              : ""}
          </ItemContainer>
        </div>
      )}
      <Spinner loading={loading} />
    </>
  );
}

export default RoomListPage;
