import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Button from "./common/Button";

function Header() {
  const navigate = useNavigate();

  const handleClickLogin = () => {
    navigate("/login");
  };

  const { user } = useAuth();

  return (
    <div className=" flex h-16 items-center bg-white border-b border-gray-100">
      <div className=" overflow-hidden h-full pl-4">
        <img className="w-full h-full " src="/RP-website-logo.png" alt="" />
      </div>
      <div className="flex  mx-auto">
        <Link
          to="/"
          className="w-36 text-center hover:font-normal hover:text-purple-600 hover:underline hover:underline-offset-2"
        >
          User
        </Link>
        <Link
          className="w-36 text-center hover:font-normal hover:text-purple-600 hover:underline hover:underline-offset-2"
          to="roomlist"
        >
          Room
        </Link>
        <Link
          className="w-36 text-center hover:font-normal hover:text-purple-600 hover:underline hover:underline-offset-2"
          to="condolist"
        >
          Condo
        </Link>
        <Link
          className="w-36 text-center hover:font-normal hover:text-purple-600 hover:underline hover:underline-offset-2 "
          to="reportlist"
        >
          Report
        </Link>
      </div>
      <div className=" basis-52 text-right pl-5 pr-7">
        {user ? (
          "Admin: " + user?.firstName ? (
            user.firstName
          ) : (
            ""
          )
        ) : (
          <Button
            text="login"
            className="bg-purple-600 hover:bg-purple-700 text-white py-3 px-6"
            onClick={() => handleClickLogin()}
          />
        )}
      </div>
    </div>
  );
}

export default Header;
