import React, { ReactNode } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

interface ItemProps {
  children: ReactNode;
  active: boolean;
  id: string;
}

function Item({ children, active, id }: ItemProps) {
  const location = useLocation();

  const navigate = useNavigate();

  const pathArray = location.pathname.split("/");

  let link = "#";

  switch (pathArray[1]) {
    case "condolist":
      link = `/condo/${id}`;
      break;
    case "roomlist":
      link = `/room/${id}`;
      break;
  }

  const handleOnClick = () => {
    navigate(link);
  };

  let item = (
    <a
      target={pathArray[1] === "roomlist" ? "_self" : "_blank"}
      className="  rounded-lg h-20 shadow-sm border border-gray-100 px-3 flex items-center cursor-pointer hover:border-gray-200 active:scale-[99%] "
      onClick={() => handleOnClick()}
    >
      {children}
    </a>
  );

  if (!active) {
    item = (
      <div className=" rounded-lg h-20 shadow-sm border  border-gray-100 px-3 flex items-center  hover:border-gray-200 ">
        {children}
      </div>
    );
  }
  return item;
}

export default Item;
