import { Report, useReport } from "../../contexts/ReportContext";
import Button from "../common/Button";

interface ReportItemProps {
  report: Report;
}

function ReportItem({ report }: ReportItemProps) {
  const { updateRead } = useReport();

  return (
    <div className="grid grid-cols-12  w-full items-center">
      <div className={`col-span-1`}>{report.id}</div>
      <div className={`col-span-4`}>{report.roomId}</div>
      <div
        className={`col-span-1 ${
          report.factorUnavailable ? "text-red-500" : ""
        }`}
      >
        ไม่ว่าง
      </div>
      <div
        className={`col-span-1 ${report.factorNotOwner ? "text-red-500" : ""}`}
      >
        ไม่ใช่เจ้าของ
      </div>
      <div
        className={`col-span-1 ${report.factorNotRoom ? "text-red-500" : ""}`}
      >
        ไม่ใช่ห้องจริง
      </div>
      <div
        className={`col-span-1 ${report.factorNotPrice ? "text-red-500" : ""}`}
      >
        ราคาไม่ถูกต้อง
      </div>
      <div className={`col-span-1 ${report.factorOther ? "text-red-500" : ""}`}>
        อื่นๆ
      </div>
      <div className="col-span-1">
        <Button text="อ่านแล้ว" onClick={() => updateRead(report.id)} disabled = {report.isRead} className ={`${report.isRead ? "bg-gray-200 cursor-default" : "bg-green-500"} text-white`} />
      </div>
      <div className="col-span-1">
        <Button text="เพิ่มเติม"  className = "bg-orange-400 text-white"  />
      </div>
    </div>
  );
}

export default ReportItem;
