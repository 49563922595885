import React from "react";

interface TopicProps {
  topic: string;
  remaining?: number;
}

function Topic({ topic, remaining }: TopicProps) {
  return (
    <div className="text-3xl font-light mb-5">
      {topic}{" "}
      <span className="text-red-400">
        {remaining ? "(" + remaining + ")" : ""}
      </span>
    </div>
  );
}

export default Topic;
