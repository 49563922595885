import React, { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

function Button({ text, ...props }: ButtonProps) {
  return (
    <button {...props} className={"button-rp-admin" + " " + props.className}>
      {text}
    </button>
  );
}

export default Button;
