import axios from "../config/axios";

export const getUserforApproval = () => {
  return axios.get("/admin/approval");
};

export const getUserDetail = (userId: string) => {
  return axios.get(`/admin/user/${userId}`);
};

export const updateUserCredit = (userId: string, credits: number) => {
  return axios.patch(`/admin/user/${userId}/credit`, { credits });
};

export const updateOwnerApproval = (userId: string, isApproved: boolean) => {
  return axios.patch(`/admin/owner/${userId}/approval`, { isApproved });
};

export const updateBrokerApproval = (userId: string, isApproved: boolean) => {
  return axios.patch(`/admin/broker/${userId}/approval`, { isApproved });
};

export const banUser = (userId: string) => {
  return axios.patch(`/admin/user/${userId}/ban`);
};

export const unbanUser = (userId: string) => {
  return axios.patch(`/admin/user/${userId}/unban`);
};

export const searchUser = (text: string) => {
  return axios.get(`/admin/user/list?name=${text}`);
};

export const getBanUser = () => {
  return axios.get(`/admin/user/ban`);
};
