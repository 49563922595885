import React from "react";
import { Transition } from "@headlessui/react";

interface SpinnerProps {
  loading: boolean;
}

function Spinner({ loading }: SpinnerProps) {
  return (
    <Transition
      show={loading}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`absolute  bg-black opacity-30 inset-0 flex flex-col items-center justify-center }`}
      >
        <div className="w-12 h-12  inline-block border-4 border-solid border-purple-600 rounded-full animate-spin border-t-transparent"></div>
        <span className="font-ligh mt-4 text-white">Loading...</span>
      </div>
    </Transition>
  );
}

export default Spinner;
