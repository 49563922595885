import Header from "../Header";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAccessToken, setAccessToken } from "../../services/localStorage";
import axios from "../../config/axios";

function Layout() {
  const navigate = useNavigate();

  return (
    <div className="h-screen bg-slate-50 ">
      <Header />
      <div className=" flex items-start mt-20 justify-center min-h-[calc(100%-4rem)] ">
        <div className=" w-128 bg-white rounded-xl px-12 py-8 shadow-sm h-4/5">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
