import {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from "../config/axios";
import {
  setAccessToken,
  removeAccessToken,
  getAccessToken,
} from "../services/localStorage";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  lineUser: string;
  googleUser: string;
}

export interface AuthContextValue {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  login: () => void;
  user: User | null;
  loading: boolean;
}

const AuthContext = createContext<AuthContextValue | null>(null);

interface AuthContextProps {
  children: ReactNode;
}

function AuthContextProvider({ children }: AuthContextProps) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);

  // const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);

  useEffect(() => {
    const run = async () => {
      const resMe = await axios.get("/user");

      if (resMe) {
        return setUser(resMe.data);
        // navigate("/userlist");
      } else {
        const res = await axios.post("/auth/refresh");

        const token = res.data.token;
        setAccessToken(token);

        const resMetry = await axios.get("/user");

        if (resMetry) {
          return setUser(resMetry.data);
        }
      }

      removeAccessToken();
      return setUser(null);
      // return navigate("/");
    };

    run();
  }, []);

  const login = async () => {
    try {
      setLoading(true);
      const res = await axios.post("/auth/login", { email, password });

      setAccessToken(res.data.token);

      const resMe = await axios.get("/user");

      if (resMe) {
        return setUser(resMe.data);
      }
      setUser(null);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const values = {
    email,
    setEmail,
    password,
    setPassword,
    login,
    user,
    loading,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;

export const useAuth = () => {
  const ctx = useContext(AuthContext);

  if (!ctx) {
    throw new Error("useAuth must be used within a AuthContextProvider");
  }
  return ctx;
};
