import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { getAccessToken } from "../../services/localStorage";

interface ProtectedRouteProps {
  children: ReactNode;
}

export default function ProtectedRoute({
  children,
}: ProtectedRouteProps): JSX.Element | null {
  const token = getAccessToken();

  const { user: authenticatedUser } = useAuth();

  if (!token ) {
    return <Navigate to={"/login"} />;
  }

  return <>{children}</>;
}
