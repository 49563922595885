import React, { ReactNode } from "react";

interface ItemContainerProps {
    children: ReactNode
}

function ItemContainer({ children }: ItemContainerProps ) {
  return <div className="flex flex-col gap-4  h-full">{children}</div>;
  
}

export default ItemContainer;
