import React, { useContext, useEffect } from "react";
import Button from "../../components/common/Button";
import ControllerContainer from "../../components/common/ControllerContainer";
import Item from "../../components/common/Item";
import ItemContainer from "../../components/common/ItemContainer";
import Search from "../../components/common/Search";
import Spinner from "../../components/common/Spinner";
import Topic from "../../components/common/Topic";
import UserItem from "../../components/user/UserItem";
import { useUser } from "../../contexts/UserContext";

function UserListPage() {
  const {
    users,
    getUserForApproval,
    getSearchUser,
    handleOnchangeSearch,
    handleClickGetBanUser,
    loading,
  } = useUser();

  useEffect(() => {
    getUserForApproval();
  }, []);

  return (
    <>
      <Spinner loading={loading} />
      {users && (
        <div>
          <Topic
            topic="รายชื่อผู้ใช้งาน ที่รอการยืนยัน"
            remaining={users ? users.length : 0}
          />
          <ControllerContainer>
            <Search
              className="search-box"
              onChange={(e: any) => handleOnchangeSearch(e.target.value)}
            />
            <div className="flex gap-4">
              <Button
                text="ถูกแบน"
                className="button-rp-admin text-red-500"
                onClick={() => handleClickGetBanUser()}
              />
              <Button
                text="รอการยืนยัน"
                className="button-rp-admin text-red-500"
                onClick={() => getUserForApproval()}
              />
            </div>
          </ControllerContainer>
          <ItemContainer>
            {users
              ? users.map((el) => (
                  <Item active={false} key={el.id} id={el.id}>
                    <UserItem value={el} />
                  </Item>
                ))
              : ""}
          </ItemContainer>
        </div>
      )}
    </>
  );
}

export default UserListPage;
