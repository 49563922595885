import React, { useState } from "react";
import { banUser, unbanUser } from "../../api/user";
import { User, useUser } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";

interface UserItemProps {
  value: User;
}

function UserItem({ value }: UserItemProps) {
  const { id, firstName, lastName, Broker, Owner, isBan, email } = value;

  const {
    approveOwner,
    rejectOwner,
    approveBroker,
    rejectBroker,
    banUser,
    unbanUser,
  } = useUser();

  const navigate = useNavigate();

  const ownerPreSignedUrl = Owner ? Owner.Identification?.presignedUrl : null;

  const brokerPreSignedUrl = Broker
    ? Broker.Identification?.presignedUrl
    : null;

  return (
    <div className="grid grid-cols-11 gap-10 w-full justify-around items-center">
      <div className=" col-span-2 truncate">
        <span>{email ? email : firstName + " " + lastName} </span>
      </div>
      <div>
        <span>สถานะ: </span>
        <span className={isBan ? "text-red-400" : "text-green-400"}>
          {isBan ? "ถูกแบน" : "ปกติ"}
        </span>
      </div>
      <div>
        <a
          className={`text-red-400 ${
            ownerPreSignedUrl
              ? "cursor-pointer"
              : "cursor-default text-gray-200"
          }`}
          href={ownerPreSignedUrl ? ownerPreSignedUrl : ""}
          target="_blank"
        >
          เอกสาร Owner
        </a>
      </div>
      <div>
        <a
          className={`text-red-400 ${
            brokerPreSignedUrl
              ? "cursor-pointer"
              : "cursor-default text-gray-200"
          }`}
          href={brokerPreSignedUrl ? brokerPreSignedUrl : ""}
          target="_blank"
        >
          เอกสาร Broker
        </a>
      </div>
      <div>
        <button
          disabled={Owner && Owner.isRequest ? false : true}
          className={` text-white button-rp-admin ${
            Owner && Owner.isRequest
              ? "bg-green-500 rounded-lg  hover:bg-green-600"
              : "bg-gray-300 "
          }  `}
          onClick={() => approveOwner(id)}
        >
          ยืนยัน Owner
        </button>
      </div>
      <div>
        <button
          disabled={Owner && Owner.isRequest ? false : true}
          className={` text-white button-rp-admin  ${
            Owner && Owner.isRequest
              ? "bg-yellow-500 rounded-lg  hover:bg-yellow-600"
              : "bg-gray-300"
          } `}
          onClick={() => rejectOwner(id)}
        >
          ปฎิเสธ Owner
        </button>
      </div>
      <div>
        <button
          disabled={Broker && Broker.isRequest ? false : true}
          className={` text-white button-rp-admin ${
            Broker && Broker.isRequest
              ? "bg-green-500 rounded-lg  hover:bg-green-600"
              : "bg-gray-300 "
          }  `}
          onClick={() => approveBroker(id)}
        >
          ยืนยัน Broker
        </button>
      </div>
      <div>
        <button
          disabled={Broker && Broker.isRequest ? false : true}
          className={` text-white button-rp-admin  ${
            Broker && Broker.isRequest
              ? "bg-yellow-500 rounded-lg  hover:bg-yellow-600"
              : "bg-gray-300"
          } `}
          onClick={() => rejectBroker(id)}
        >
          ปฎิเสธ Broker
        </button>
      </div>
      <div className="w-20">
        <button
          className={` text-white button-rp-admin  ${
            !isBan
              ? "bg-red-500 rounded-lg  hover:bg-red-600 cursor-pointer"
              : "bg-green-500 rounded-lg  hover:bg-green-600 cursor-pointer"
          } `}
          onClick={isBan ? () => unbanUser(id) : () => banUser(id)}
        >
          {isBan ? "ยกเลิกแบน" : "แบน"}
        </button>
      </div>
      <div className="w-full ">
        <button
          className={`  button-rp-admin cursor-pointer`}
          onClick={() => navigate(`/user/${id}`)}
        >
          {"รายละเอียด"}
        </button>
      </div>
    </div>
  );
}

export default UserItem;
