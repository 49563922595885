import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import ControllerContainer from "../../components/common/ControllerContainer";
import Item from "../../components/common/Item";
import ItemContainer from "../../components/common/ItemContainer";
import Search from "../../components/common/Search";
import Spinner from "../../components/common/Spinner";
import Topic from "../../components/common/Topic";
import CondoItem from "../../components/condo/CondoItem";
import { useCondo } from "../../contexts/CondoContext";

function CondoListPage() {
  const {
    condos,
    getCondoApproval,
    handleChangeSearch,
    createCondo,
    handleClickUpdateGooglePlaceId,
    loading,
  } = useCondo();

  const navigate = useNavigate();

  useEffect(() => {
    getCondoApproval();
  }, []);

  const handleClickCreateCondo = async () => {
    const condo = await createCondo();
    navigate(`/condo/${condo.id}`);
  };

  return (
    <>
      <div>
        <Topic
          topic="รายชื่อคอนโด ที่มีการเพิ่มเข้ามา"
          remaining={condos ? condos.length : 0}
        />
        <ControllerContainer>
          <Search
            className="search-box"
            onChange={(e: any) => handleChangeSearch(e.target.value)}
          />
          <div className="flex gap-4">
            <Button
              text="อัพเดท Google Place"
              className="button-rp-admin text-red-500"
              onClick={() => handleClickUpdateGooglePlaceId()}
            />
            <Button
              text="รอการยืนยัน"
              className="button-rp-admin text-red-500"
              onClick={() => getCondoApproval()}
            />
            <Button
              text="สร้างคอนโด"
              className="button-rp-admin text-green-500"
              onClick={() => handleClickCreateCondo()}
            />
          </div>
        </ControllerContainer>
        <ItemContainer>
          {condos
            ? condos.map((el) => (
                <Item active={true} key={el.id} id={el.id}>
                  <CondoItem value={el} />
                </Item>
              ))
            : ""}
        </ItemContainer>
      </div>
      <Spinner loading={loading} />
    </>
  );
}

export default CondoListPage;
