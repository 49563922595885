import {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from "react";
import * as userApi from "../api/user";
import * as brokerApi from "../api/broker";
import axios from "../config/axios";

interface Identification {
  presignedUrl: string;
}

export interface User {
  id: string;
  firstName: string | null;
  lastName: string | null;
  isBan: boolean;
  email: string | null;
  isAuthenticated: boolean;
  credits: number;
  Broker: {
    id: string;
    isRequest: boolean;
    Identification: Identification | null;
    listLimit: number;
  };
  Owner: {
    isRequest: boolean;
    Identification: Identification | null;
  };
}

export interface UserContextValue {
  users: User[] | null;
  userDetail: User | null;
  getUserForApproval: () => Promise<void>;
  approveOwner: (userId: string) => Promise<void>;
  rejectOwner: (userId: string) => Promise<void>;
  approveBroker: (userId: string) => Promise<void>;
  rejectBroker: (userId: string) => Promise<void>;
  banUser: (userId: string) => Promise<void>;
  unbanUser: (userId: string) => Promise<void>;
  getSearchUser: (text: string) => Promise<void>;
  handleOnchangeSearch: (text: string) => void;
  handleClickGetBanUser: () => Promise<void>;
  loading: boolean;
  fetchUserDetail: (userId: string) => Promise<void>;
  updateUserCredit: (userId: string, credits: number) => Promise<void>;
  updateBrokerListLimit: (
    brokerId: string | undefined,
    command: string,
    userId: string | undefined
  ) => Promise<void>;
}

const UserContext = createContext<UserContextValue | null>(null);

interface UserContextProps {
  children: ReactNode;
}

function UserContextProvider({ children }: UserContextProps) {
  const [users, setUsers] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(false);

  const getUserForApproval = async () => {
    try {
      // setUsers(null);
      setLoading(true);
      const res = await userApi.getUserforApproval();

      setUsers(res.data.users);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const approveOwner = async (userId: string) => {
    const res = await userApi.updateOwnerApproval(userId, true);

    setUsers(res.data.users);
  };

  const rejectOwner = async (userId: string) => {
    const res = await userApi.updateOwnerApproval(userId, false);

    setUsers(res.data.users);
  };

  const approveBroker = async (userId: string) => {
    const res = await userApi.updateBrokerApproval(userId, true);

    setUsers(res.data.users);
  };

  const rejectBroker = async (userId: string) => {
    const res = await userApi.updateBrokerApproval(userId, false);

    setUsers(res.data.users);
  };

  const banUser = async (userId: string) => {
    await userApi.banUser(userId);

    getUserForApproval();
  };

  const unbanUser = async (userId: string) => {
    await userApi.unbanUser(userId);

    getUserForApproval();
  };

  const getSearchUser = async (text: string) => {
    const res = await userApi.searchUser(text);

    setUsers(res.data.user);
  };

  const handleOnchangeSearch = (text: string) => {
    getSearchUser(text);
  };

  const handleClickGetBanUser = async () => {
    setUsers(null);
    const res = await userApi.getBanUser();

    setUsers(res.data.user);
  };

  const fetchUserDetail = async (userId: string) => {
    setLoading(true);
    setUserDetail(null);
    const res = await userApi.getUserDetail(userId);
    setUserDetail(res.data.user);
    setLoading(false);
  };

  const updateUserCredit = async (userId: string, credits: number) => {
    console.log({ userId, credits });
    if (typeof credits === "number" && !isNaN(Number(credits))) {
      setLoading(true);
      await userApi.updateUserCredit(userId, credits);
      await fetchUserDetail(userId);
      setLoading(false);
    }
  };

  const updateBrokerListLimit = async (
    brokerId: string | undefined,
    command: string,
    userId: string | undefined
  ) => {
    if (brokerId) {
      if (command === "add") {
        await brokerApi.addListLimit(brokerId);
      } else if (command === "minus") {
        await brokerApi.minusListLimit(brokerId);
      }
    }
    if (userId) {
      fetchUserDetail(userId);
    }
  };

  const values = {
    users,
    userDetail,
    getUserForApproval,
    approveOwner,
    rejectOwner,
    approveBroker,
    rejectBroker,
    banUser,
    unbanUser,
    getSearchUser,
    handleOnchangeSearch,
    handleClickGetBanUser,
    loading,
    fetchUserDetail,
    updateUserCredit,
    updateBrokerListLimit,
  };

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

export default UserContextProvider;

export const useUser = () => {
  const ctx = useContext(UserContext);

  if (!ctx) {
    throw new Error("useUser must be used within a UserContextProvider");
  }
  return ctx;
};
