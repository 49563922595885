import {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from "react";
import * as roomApi from "../api/room";

export interface Deed {
  id: number;
  roomId: string;
  url: string;
  presignedUrl: string;
}

export interface Room {
  id: string;
  isApproved: boolean;
  isAvailable: boolean;
  isDraft: boolean;
  availableDate: string;
  autoRenewStatus: boolean;
  adsExpiry: Date | null;
  Owner: {
    user: {
      firstName: string;
      lastName: string;
    };
  };
  Condo: {
    name: string;
  };
  RoomInfo: {
    roomNumber: number;
    bathroomNumber: number;
    area: number;
    floor: number;
  };
  RoomContracts: {
    price: number;
    deposite: number;
    advance: number;
    minPeriod: number;
  };
  rating: number;
  RoomDeeds: Deed[];
}

export interface RoomContextValue {
  rooms: Room[] | null;
  roomDetail: Room | null;
  fetchRoomForApproval: () => Promise<void>;
  fetchRoomDetail: (roomId: string) => Promise<void>;
  approveRoom: (roomId: string) => Promise<void>;
  rejectRoom: (roomId: string) => Promise<void>;
  loading: boolean;
  searchRoom: (roomId: string | null) => Promise<void>;
  updateAutoRenew: (roomId: string, autoRenewStatus: boolean) => Promise<void>;
  updateAdsExpiry: (
    roomId: string | undefined,
    adsExpiry: Date | null
  ) => Promise<void>;
}

interface RoomContextProps {
  children: ReactNode;
}

const RoomContext = createContext<RoomContextValue | null>(null);

function RoomContextProvider({ children }: RoomContextProps) {
  const [rooms, setRooms] = useState<Room[] | null>(null);
  const [roomDetail, setRoomDetail] = useState<Room | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchRoomForApproval = async () => {
    try {
      setRooms(null);
      setLoading(true);
      const res = await roomApi.getRoomsforApproval();

      setRooms(res.data.rooms);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoomDetail = async (roomId: string) => {
    setRoomDetail(null);
    const res = await roomApi.getRoomDetail(roomId);

    setRoomDetail(res.data.room);
  };

  const approveRoom = async (roomId: string) => {
    await roomApi.approveRoom(roomId);
  };

  const rejectRoom = async (roomId: string) => {
    await roomApi.rejectRoom(roomId);
  };

  const searchRoom = async (text: string | null) => {
    if (text) {
      setLoading(true);
      const res = await roomApi.getSearchRooms(text);
      setRooms(res.data.rooms);
      setLoading(false);
    }
  };

  const updateAutoRenew = async (roomId: string, autoRenewStatus: boolean) => {
    try {
      setLoading(true);
      await roomApi.updateAutoRenew(roomId, autoRenewStatus);
      fetchRoomDetail(roomId);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateAdsExpiry = async (
    roomId: string | undefined,
    adsExpiry: Date | null
  ) => {
    if (roomId && adsExpiry) {
      await roomApi.updateAdsExpiry(roomId, adsExpiry);
      await fetchRoomDetail(roomId);
    }
    return;
  };

  const values = {
    rooms,
    roomDetail,
    fetchRoomForApproval,
    fetchRoomDetail,
    approveRoom,
    rejectRoom,
    loading,
    searchRoom,
    updateAutoRenew,
    updateAdsExpiry,
  };

  return <RoomContext.Provider value={values}>{children}</RoomContext.Provider>;
}

export default RoomContextProvider;

export const useRoom = () => {
  const ctx = useContext(RoomContext);

  if (!ctx) {
    throw new Error("useRoom must be used within a RoomContextProvider");
  }

  return ctx;
};
