import logo from "./logo.svg";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CondoDetailsPage from "./pages/Condo/CondoDetailsPage";
import CondoListPage from "./pages/Condo/CondoListPage";
import RoomDetailsPage from "./pages/Room/RoomDetailsPage";
import RoomListPage from "./pages/Room/RoomListPage";
import UserDetailsPage from "./pages/User/UserDetailsPage";
import UserListPage from "./pages/User/UserListPage";
import Layout from "./components/layout/Layout";
import LoginPage from "./pages/LoginPage";
import { useAuth } from "./contexts/AuthContext";
import ReportDetailPage from "./pages/Report/ReportDetailPage";
import ReportListPage from "./pages/Report/ReportListPage";
import RedirectIfAuthenticate from "./features/auth/RedirectIfAuthenticate";
import ProtectedRoute from "./features/auth/ProtectedRoute";

// const publicRoute = [
//   {
//     path: "",
//     element: <LoginPage />,
//   },
// ];

// const privateRoute = [
//   {
//     path: "condolist",
//     element: <CondoListPage />,
//   },
//   {
//     path: "roomlist",
//     element: <RoomListPage />,
//   },
//   {
//     path: "room/:roomId",
//     element: <RoomDetailsPage />,
//   },

//   {
//     path: "/userlist",
//     element: <UserListPage />,
//   },
//   {
//     path: "user/:userId",
//     element: <UserDetailsPage />,
//   },
//   {
//     path: "condo/:condoId",
//     element: <CondoDetailsPage />,
//   },
//   {
//     path: "reportlist",
//     element: <ReportDetailPage />,
//   },
//   {
//     path: "report/:reportId",
//     element: <ReportListPage />,
//   },
// ];

function App() {
  const { user } = useAuth();

  const router = createBrowserRouter([
    {
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "/condolist",
          element: <CondoListPage />,
        },
        {
          path: "/roomlist",
          element: <RoomListPage />,
        },
        {
          path: "/room/:roomId",
          element: <RoomDetailsPage />,
        },

        {
          path: "/",
          element: <UserListPage />,
        },
        {
          path: "/user/:userId",
          element: <UserDetailsPage />,
        },
        {
          path: "/condo/:condoId",
          element: <CondoDetailsPage />,
        },
        {
          path: "/reportlist",
          element: <ReportListPage />,
        },
        {
          path: "/report/:reportId",
          element: <ReportDetailPage />,
        },
      ],
    },
    {
      path: "/login",
      element: (
        <RedirectIfAuthenticate>
          <LoginPage />
        </RedirectIfAuthenticate>
      ),
    },
  ]);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
