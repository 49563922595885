import axios from "../config/axios";

export const getUnread = () => {
  return axios.get("/admin/report?isRead=false");
};

export const getRead = () => {
  return axios.get("/admin/report?isRead=true");
};

export const updateRead = (reportId: number) => {
  return axios.patch(`/admin/report/${reportId}`);
};
