import {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
} from "react";
import * as reportApi from "../api/report";

export interface Report {
  id: number;
  userId: string;
  roomId: string;
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  factorUnavailable: boolean;
  factorNotOwner: boolean;
  factorNotRoom: boolean;
  factorNotPrice: false;
  factorOther: string | null;
  isRead: boolean;
  createdAt: Date;
}

export interface ReportContextValue {
  loading: boolean;
  reports: Report[] | null;
  updateRead: (reportId: number) => Promise<void>;
  getReadReport: () => Promise<void>;
  getUnreadReport: () => Promise<void>;
}

const ReportContext = createContext<ReportContextValue | null>(null);

interface ReportContextProps {
  children: ReactNode;
}

function ReportContextProvider({ children }: ReportContextProps) {
  const [reports, setReports] = useState<Report[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getUnreadReport();
  }, []);

  const getUnreadReport = async () => {
    try {
        setLoading(true);
      const res = await reportApi.getUnread();

      if (res?.data?.reports) {
        setReports(res.data.reports);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getReadReport = async () => {
    try {
      setLoading(true);
      const res = await reportApi.getRead();

      if (res?.data?.reports) {
        setReports(res.data.reports);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updateRead = async (reportId: number) => {
    try {
      setLoading(true);
      await reportApi.updateRead(reportId);
      await getUnreadReport();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const values = {
    loading,
    reports,
    updateRead,
    getReadReport,
    getUnreadReport
  };

  return (
    <ReportContext.Provider value={values}>{children}</ReportContext.Provider>
  );
}

export default ReportContextProvider;

export const useReport = () => {
  const ctx = useContext(ReportContext);
  if (!ctx) {
    throw new Error("useReport must be used within a ReportContextProvider");
  }

  return ctx;
};
