const genFormData = (fileList: FileList | null) => {
  if (fileList === null) {
    return null;
  }

  const formData = new FormData();

  const files = Array.from(fileList);

  for (let file of files) {
    formData.append("files", file);
  }

  return formData;
};

export default genFormData;
