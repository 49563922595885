import React, { HTMLAttributes, ReactNode } from "react";

interface CondoImageItemProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

function CondoImageItem({ children }: CondoImageItemProps) {
  return (
    <div className="border rounded-md border-gray-100 pr-2 h-24 ml-4 shadow-sm   flex flex-col justify-center hover:border-gray-200">
      {children}
    </div>
  );
}

export default CondoImageItem;
