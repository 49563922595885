import axios from "axios";
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from "../services/localStorage";
import { refreshAccessToken } from "../api/auth";

axios.defaults.baseURL = `${process.env.REACT_APP_ENDPOINT_URL}`;

axios.interceptors.request.use(
  (config: any) => {
    const token = getAccessToken();

    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    return config;
  },
  (err) => Promise.reject(err)
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.responsestatus === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();

        setAccessToken(newAccessToken);

        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        return axios(originalRequest);
      } catch (err) {
        removeAccessToken();
        await axios.delete("/auth/signout");
        throw new Error("Unable to refresh token");
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
