import { ReactNode, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

interface RedirectIfAuthenticateProps {
  children: ReactNode;
}

export default function RedirectIfAuthenticate({
  children,
}: RedirectIfAuthenticateProps): JSX.Element | null {
  const { user: authenticatedUser } = useAuth();

  if (authenticatedUser) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}
