import React, { useEffect, useState } from "react";
import ControllerContainer from "../../components/common/ControllerContainer";
import Item from "../../components/common/Item";
import ItemContainer from "../../components/common/ItemContainer";
import Search from "../../components/common/Search";
import Topic from "../../components/common/Topic";
import RoomItem from "../../components/room/RoomItem";
import Form from "../../components/common/Form";
import Input from "../../components/common/Input";
import List from "../../components/common/List";
import RoomDocItem from "../../components/room/RoomDocItem";
import Button from "../../components/common/Button";
import {
  redirect,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { useRoom } from "../../contexts/RoomContext";
import Spinner from "../../components/common/Spinner";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function RoomDetailsPage() {
  const {
    loading,
    roomDetail,
    fetchRoomDetail,
    approveRoom,
    rejectRoom,
    updateAutoRenew,
    updateAdsExpiry,
  } = useRoom();

  const [startDate, setStartDate] = useState<Date | null>(new Date());

  useEffect(() => {
    if (roomDetail && roomDetail.adsExpiry) {
      setStartDate(new Date(roomDetail.adsExpiry));
    }
  }, [roomDetail]);

  const location = useLocation();
  const { roomId } = useParams();

  const pathArray = location.pathname.split("/");

  const path = pathArray[2];

  useEffect(() => {
    fetchRoomDetail(path);
  }, []);

  let navigate = useNavigate();

  const availableDate = roomDetail?.availableDate
    ? new Date(roomDetail.availableDate)
    : null;

  const readableDate = availableDate
    ? availableDate.getDate() +
      "/" +
      availableDate.getMonth() +
      "/" +
      availableDate.getFullYear()
    : "No available date shown";

  const handleApprove = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (roomDetail) {
      await approveRoom(roomDetail.id);
      navigate("/roomlist");
    }
  };

  const handleReject = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (roomDetail) {
      await rejectRoom(roomDetail.id);
      navigate("/roomlist");
    }
  };

  const handleAutoRenew = (e: React.FormEvent) => {
    e.preventDefault();
    if (roomDetail) {
      updateAutoRenew(roomDetail.id, !roomDetail.autoRenewStatus);
    }
  };

  const handleUpdateAdsExpiry = async (e: React.FormEvent) => {
    e.preventDefault();

    if (roomId && startDate) {
      await updateAdsExpiry(roomId, startDate);
    }
  };

  return (
    <>
      <Spinner loading={loading} />
      {roomDetail && (
        <div>
          <Topic topic={"ห้อง "} />
          <Form>
            <div>
              <span className="font-medium">*ชื่อคอนโด : </span>
              <span>{roomDetail.Condo.name}</span>
            </div>
            <div>
              <span className="font-medium">*พื้นที่ห้อง : </span>
              <span>{roomDetail.RoomInfo.area} ตารางเมตร</span>
            </div>
            <div>
              <span className="font-medium">*ชั้น : </span>
              <span>{roomDetail.RoomInfo.floor}</span>
            </div>
            <div>
              <span className="font-medium">*ราคา : </span>
              <span>{roomDetail.RoomContracts.price} บาท</span>
            </div>
            <div>
              <span className="font-medium">มัดจำ : </span>
              <span>{roomDetail.RoomContracts.deposite} บาท</span>
            </div>
            <div>
              <span className="font-medium">จ่ายล่วงหน้า : </span>
              <span>{roomDetail.RoomContracts.advance} บาท</span>
            </div>
            <div>
              <span className="font-medium">ระยะเวลาเช่าขั้นต่ำ : </span>
              <span>{roomDetail.RoomContracts.minPeriod} เดือน</span>
            </div>
            <div>
              <span className="font-medium">สถานะเอกสาร : </span>
              <span>
                {roomDetail.isApproved ? "ยืนยันแล้ว" : "รอการยืนยัน"}{" "}
              </span>
            </div>
            <div>
              <span className="font-medium">สถานะห้อง : </span>
              <span>{roomDetail.isAvailable ? "ว่าง" : "ไม่ว่าง"}</span>
            </div>
            <div>
              <span className="font-medium">สถานะการเผยแพร่ : </span>
              <span>
                {roomDetail.isDraft ? "ยังไม่เผยแพร่" : "เผยแพร่แล้ว"}{" "}
              </span>
            </div>
            <div>
              <span className="font-medium">วันที่ว่าง : </span>
              <span>{readableDate} </span>
            </div>
            <div>
              <span className="font-medium">คะแนน : </span>
              <span>{roomDetail.rating ? roomDetail.rating : 0}/10 </span>
            </div>
            <div>
              <span className="font-medium">Auto-renew : </span>
              <span
                className={`${
                  roomDetail.autoRenewStatus ? "text-red-500" : "text-green-500"
                }`}
              >
                {roomDetail.autoRenewStatus ? "Active" : "Inactive"}{" "}
              </span>
              <Button
                className="ml-4 bg-red-500 text-white"
                text="เปลี่ยน"
                onClick={(e) => handleAutoRenew(e)}
              />
            </div>
            <div>
              <span className="font-medium">Advertise Expiry : </span>
              <span>
                {roomDetail?.adsExpiry
                  ? new Date(roomDetail.adsExpiry).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })
                  : "-"}
              </span>
            </div>
            <div>
              <span>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="border h-9 rounded-md px-3 cursor-pointer inline "
                />
              </span>
              <span>
                <Button
                  text="อัพเดท"
                  className="mt-4 bg-red-500 text-white"
                  onClick={(e) => handleUpdateAdsExpiry(e)}
                />
              </span>
            </div>

            <div className="font-medium">เอกสาร</div>
            <List>
              {roomDetail.RoomDeeds.map((el, idx) => (
                <RoomDocItem href={el.presignedUrl} order={idx + 1} />
              ))}
            </List>
            {!roomDetail.isApproved && (
              <div className="flex gap-10 justify-end mt-10">
                <Button
                  text="ปฎิเสธ"
                  className="bg-red-500 text-white"
                  onClick={(e) => handleReject(e)}
                />
                <Button
                  text="ยืนยัน"
                  className="bg-green-500 text-white"
                  onClick={(e) => handleApprove(e)}
                />
              </div>
            )}
          </Form>
        </div>
      )}
    </>
  );
}

export default RoomDetailsPage;
