import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import AuthContextProvider from "./contexts/AuthContext";
import UserContextProvider from "./contexts/UserContext";
import CondoContextProvider from "./contexts/CondoContext";
import RoomContextProvider from "./contexts/RoomContext";
import ReportContextProvider from "./contexts/ReportContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ReportContextProvider>
      <AuthContextProvider>
        <UserContextProvider>
          <CondoContextProvider>
            <RoomContextProvider>
              <App />
            </RoomContextProvider>
          </CondoContextProvider>
        </UserContextProvider>
      </AuthContextProvider>
    </ReportContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
