import React from "react";

function List({ children, ...props }: React.HTMLProps<HTMLLIElement>) {
  return (
    <li {...props} className={"flex flex-col gap-4" + " " + props.className}>
      {children}
    </li>
  );
}

export default List;
