import axios from "../config/axios";

export const getRoomsforApproval = () => {
  return axios.get("/admin/room");
};

export const getRooms = () => {
  return axios.get("/admin/room");
};

export const getRoomDetail = (roomId: string) => {
  return axios.get(`/admin/room/${roomId}/deeds`);
};

export const approveRoom = (roomId: string) => {
  return axios.patch(`/admin/room/${roomId}/approve`);
};

export const rejectRoom = (roomId: string) => {
  return axios.patch(`/admin/room/${roomId}/reject`);
};

export const getSearchRooms = (roomId: string) => {
  return axios.get(`/admin/room/all?search=${roomId}`);
};

export const updateAutoRenew = (roomId: string, autoRenewStatus: boolean) => {
  return axios.patch(`/admin/room/${roomId}/autoRenew`, { autoRenewStatus });
};

export const updateAdsExpiry = (roomId: string, adsExpiry: Date) => {
  return axios.patch(`/admin/room/${roomId}/adsExpiry`, { adsExpiry });
};
