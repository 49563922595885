import {
  createContext,
  ReactNode,
  useState,
  useContext,
  ChangeEvent,
  useEffect,
} from "react";
import * as condoApi from "../api/condo";
import axios from "../config/axios";
import genFormData from "../services/genFormData";

export interface CondoPhoto {
  id: number;
  orderNumber: string;
  condoId: string;
  url: string;
  isApproved: boolean;
}

export interface RoomPrice {
  b0?: number;
  b1?: number;
  b2?: number;
  b3?: number;
  b4?: number;
}

export interface Condo {
  id: string;
  name: string | null;
  nameTh: string | null;
  longitude: number | null;
  latitude: number | null;
  isApproved: boolean;
  address: string | null;
  addressTh: string | null;
  district: string | null;
  districtTh: string | null;
  area: number | null;
  floor: number | null;
  avgPrice: RoomPrice;
  suggestPool: boolean;
  suggestGym: boolean;
  suggestPublicLaundry: boolean;
  googlePlaceId: string | null;
  adsExpiry: Date | null;
  CondoPhotos: CondoPhoto[];
}

export interface UpdateCondo {
  id?: string;
  name?: string | null;
  nameTh?: string | null;
  longitude?: number | null;
  latitude?: number | null;
  isApproved?: boolean;
  address?: string | null;
  addressTh?: string | null;
  district?: string | null;
  districtTh?: string | null;
  area?: number | null;
  floor?: number | null;
  avgPrice?: RoomPrice;
  suggestPool?: boolean;
  suggestGym?: boolean;
  suggestPublicLaundry?: boolean;
  googlePlaceId?: string | null;
  CondoPhotos?: CondoPhoto[];
}

// export interface GooglePlace {
//   place_id: string | undefined;
//   geometry: Geometry;
// }

export interface CondoContextValue {
  condos: Condo[] | null;
  condoDetail: Condo | null;
  googlePlace: google.maps.places.PlaceResult | null;
  getCondoApproval: () => Promise<void>;
  handleChangeSearch: (text: string) => Promise<void>;
  createCondo: () => Promise<any>;
  getCondoDetail: (condoId: string) => Promise<void>;
  handleOnChange: (condo: UpdateCondo) => void;
  handleGooglePlaceSelect: (
    googlePlace: google.maps.places.PlaceResult
  ) => void;
  handleSaveCondo: (condoId: string) => Promise<void>;
  handlePublishCondo: (condoId: string) => Promise<void>;
  handleClickReOrder: (newOrder: Order[]) => Promise<void>;
  handleUploadImages: (
    condoId: string,
    e: ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  handleClickUpdateGooglePlaceId: () => Promise<void>;
  handlePublishCondoPhoto: (
    imageId: number,
    newOrder: Order[]
  ) => Promise<void>;
  numberOfActivePhotos: number;
  handleDeleteCondoPhoto: (imageId: number) => Promise<void>;
  loading: boolean;
  updateCondoAdsExpiry: (
    condoId: string | undefined,
    adsExpiry: Date | null
  ) => Promise<void>;
}

const CondoContext = createContext<CondoContextValue | null>(null);

interface CondoContextProps {
  children: ReactNode;
}

export interface Order {
  id: number;
  orderNumber: number;
}

function CondoContextProvider({ children }: CondoContextProps) {
  const [condos, setCondos] = useState<Condo[] | null>(null);
  const [condoDetail, setCondoDetail] = useState<Condo | null>(null);
  const [order, setOrder] = useState<Order[] | null>(null);
  const [googlePlace, setGooglePlace] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const run = async () => {
      if (condoDetail && order) {
        const res = await condoApi.updateImageOrder(condoDetail.id, order);

        setCondoDetail(res.data.condo);
      }
    };

    run();
  }, [order]);

  const approvedPhotos = condoDetail?.CondoPhotos.filter((el) => el.isApproved);

  const numberOfActivePhotos = approvedPhotos?.length
    ? approvedPhotos.length
    : 0;

  const getCondoApproval = async () => {
    try {
      setCondos(null);
      setLoading(true);
      const res = await condoApi.getCondoApproval();

      setCondos(res.data.condos);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleClickUpdateGooglePlaceId = async () => {
    setCondos(null);
    const res = await condoApi.getCondoWithoutGooglePlaceId();

    setCondos(res.data.condos);
  };

  const handleChangeSearch = async (text: string) => {
    const res = await condoApi.searchCondo(text);
    setCondos(res.data.condos);
  };

  const createCondo = async () => {
    try {
      setLoading(true);

      setCondos(null);

      const res = await condoApi.createCondo();

      setCondoDetail(res.data.condo);

      return res.data.condo;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = (input: UpdateCondo) => {
    // if (Object.keys(input).length > 0 && condoDetail !== null) {
    //   return setCondoDetail({ ...condoDetail, ...input });
    // }

    return setCondoDetail((e) => {
      if (e !== null) {
        return { ...e, ...input };
      }
      return null;
    });

    // return setCondoDetail(condoDetail);
  };

  const getCondoDetail = async (condoId: string) => {
    try {
      // setCondoDetail(null);
      setLoading(true);
      const res = await condoApi.getDetail(condoId);

      setCondoDetail(res.data.condo);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleGooglePlaceSelect = (
    googlePlace: google.maps.places.PlaceResult
  ) => {
    setGooglePlace(googlePlace);
  };

  const handleSaveCondo = async (condoId: string) => {
    let inputs: UpdateCondo = {};
    if (condoDetail) {
      inputs.name = condoDetail.name;
      inputs.nameTh = condoDetail.nameTh;
      inputs.address = condoDetail.addressTh;
      inputs.addressTh = condoDetail.addressTh;
      inputs.longitude = condoDetail.longitude;
      inputs.latitude = condoDetail.latitude;
      inputs.district = condoDetail.district;
      inputs.districtTh = condoDetail.districtTh;
      inputs.googlePlaceId = condoDetail.googlePlaceId;
      inputs.area = condoDetail.area;
      inputs.floor = condoDetail.floor;
      inputs.suggestPool = condoDetail.suggestPool;
      inputs.suggestGym = condoDetail.suggestGym;
      inputs.suggestPublicLaundry = condoDetail.suggestPublicLaundry;
      inputs.isApproved = false;
      inputs.avgPrice = condoDetail.avgPrice;
    }

    await condoApi.updateCondo(condoId, inputs);

    await getCondoDetail(condoId);

    window.location.reload();
  };

  const handlePublishCondo = async (condoId: string) => {
    let inputs: UpdateCondo = {};
    if (condoDetail) {
      inputs.name = condoDetail.name;
      inputs.nameTh = condoDetail.nameTh;
      inputs.address = condoDetail.addressTh;
      inputs.addressTh = condoDetail.addressTh;
      inputs.longitude = condoDetail.longitude;
      inputs.latitude = condoDetail.latitude;
      inputs.googlePlaceId = condoDetail.googlePlaceId;
      inputs.area = condoDetail.area;
      inputs.floor = condoDetail.floor;
      inputs.suggestPool = condoDetail.suggestPool;
      inputs.suggestGym = condoDetail.suggestGym;
      inputs.suggestPublicLaundry = condoDetail.suggestPublicLaundry;
      inputs.isApproved = true;
    }

    await condoApi.updateCondo(condoId, inputs);

    await getCondoDetail(condoId);
    window.location.reload();
  };

  const handleUploadImages = async (
    condoId: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const formData = genFormData(e.target.files);

    if (!formData) {
      return;
    }

    const res = await condoApi.uploadCondoImages(condoId, formData);

    const condosWithNewPhotos: Condo | null = res.data.condos;

    if (!condosWithNewPhotos) {
      return;
    }

    const newPhotos: CondoPhoto[] = condosWithNewPhotos.CondoPhotos;

    const newCondoDetail: Condo | null = condoDetail
      ? { ...condoDetail, CondoPhotos: newPhotos }
      : null;

    setCondoDetail(newCondoDetail);
  };

  const handleClickReOrder = async (newOrder: Order[]) => {
    setOrder(newOrder);
  };

  const handlePublishCondoPhoto = async (
    imageId: number,
    newOrder: Order[]
  ) => {
    await condoApi.updateCondoPhotoApproval(imageId);

    setOrder(newOrder);
  };

  const handleDeleteCondoPhoto = async (imageId: number) => {
    await condoApi.deleteCondoPhoto(imageId);
  };

  const updateCondoAdsExpiry = async (
    condoId: string | undefined,
    adsExpiry: Date | null
  ) => {
    if (condoId && adsExpiry) {
      await condoApi.updateAdsExpiry(condoId, adsExpiry);
      await getCondoDetail(condoId);
    }
    return;
  };

  const values = {
    condos,
    condoDetail,
    googlePlace,
    getCondoApproval,
    handleChangeSearch,
    createCondo,
    getCondoDetail,
    handleOnChange,
    handleGooglePlaceSelect,
    handleSaveCondo,
    handlePublishCondo,
    handleUploadImages,
    handleClickReOrder,
    handleClickUpdateGooglePlaceId,
    handlePublishCondoPhoto,
    numberOfActivePhotos,
    handleDeleteCondoPhoto,
    loading,
    updateCondoAdsExpiry,
  };

  return (
    <CondoContext.Provider value={values}>{children}</CondoContext.Provider>
  );
}

export default CondoContextProvider;

export const useCondo = () => {
  const ctx = useContext(CondoContext);

  if (!ctx) {
    throw new Error("useCondo must be used within a CondoContextProvider");
  }

  return ctx;
};
