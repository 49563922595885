import React, { ReactNode } from "react";
import {
  BsFillArrowDownCircleFill,
  BsFillArrowUpCircleFill,
} from "react-icons/bs";
import { CondoPhoto, useCondo } from "../../contexts/CondoContext";

interface CondoItemLayoutProps {
  value: CondoPhoto;
  images: CondoPhoto[];
}

function CondoItemLayout({ value, images }: CondoItemLayoutProps) {
  const { id, orderNumber, condoId, url, isApproved } = value;

  // if (value && image1 && image2) {
  //   console.log({
  //     image0: image1.orderNumber,
  //     image1: value.orderNumber,
  //     image2: image2.orderNumber,
  //   });
  // }

  const {
    numberOfActivePhotos,
    handleClickReOrder,
    handlePublishCondoPhoto,
    handleDeleteCondoPhoto,
    getCondoDetail,
  } = useCondo();

  const reOrderImages = [...images];

  const handleClickDown = () => {
    if (Number(orderNumber) < numberOfActivePhotos - 1) {
      const output = reOrderImages.splice(Number(orderNumber), 2);
      reOrderImages.splice(Number(orderNumber), 0, output[1], output[0]);
      console.log({ reOrderImages });
      const reOrderInput = reOrderImages.map((el, idx) => ({
        id: el.id,
        orderNumber: idx,
      }));

      handleClickReOrder(reOrderInput);
    }
  };

  const handleClickUp = () => {
    if (Number(orderNumber) > 0) {
      const output = reOrderImages.splice(Number(orderNumber) - 1, 2);
      reOrderImages.splice(Number(orderNumber) - 1, 0, output[1], output[0]);

      const reOrderInput = reOrderImages.map((el, idx) => ({
        id: el.id,
        orderNumber: idx,
      }));

      handleClickReOrder(reOrderInput);
    }
  };

  const handleClickPublish = () => {
    if (!isApproved) {
      const output = reOrderImages.splice(Number(orderNumber), 1);
      reOrderImages.splice(numberOfActivePhotos, 0, output[0]);

      const reOrderInput = reOrderImages.map((el, idx) => ({
        id: el.id,
        orderNumber: idx,
      }));

      handlePublishCondoPhoto(id, reOrderInput);
    }
  };

  const handleClickUnPublish = () => {
    if (isApproved) {
      const numberOfPhotos = reOrderImages.length;
      const output = reOrderImages.splice(Number(orderNumber), 1);
      reOrderImages.splice(numberOfPhotos - 1, 0, output[0]);

      const reOrderInput = reOrderImages.map((el, idx) => ({
        id: el.id,
        orderNumber: idx,
      }));

      handlePublishCondoPhoto(id, reOrderInput);
    }
  };

  const handleClickDeletePhoto = async () => {
    await handleDeleteCondoPhoto(id);
    await getCondoDetail(condoId);
  };

  return (
    <div className="flex justify-between items-center  h-full">
      <div className="object-cover bg-center bg-no-repeat h-full  w-36 flex items-center justify-center overflow-hidden ">
        <a
          className=" rounded-md overflow-hidden w-full bg-red-200s"
          href={`${process.env.REACT_APP_SPACES_ENDPOINT}/${url}`}
          target="_blank"
        >
          <img src={`${process.env.REACT_APP_SPACES_ENDPOINT}/${url}`} alt="" className=" " />
        </a>
      </div>
      <div className="bg-red-400 rounded-full  active:scale-95 text-white">
        <button
          className="flex "
          onClick={() => handleClickDown()}
          disabled={isApproved ? false : true}
        >
          <BsFillArrowDownCircleFill
            className={`w-10 h-10 bg-white ${
              isApproved ? "text-blue-500" : "text-gray-300"
            }`}
          />
        </button>
      </div>
      <div className="bg-red-400 rounded-full  active:scale-95 text-white">
        <button
          className="flex "
          onClick={() => handleClickUp()}
          disabled={isApproved ? false : true}
        >
          <BsFillArrowUpCircleFill
            className={`w-10 h-10 bg-white ${
              isApproved ? "text-blue-500" : "text-gray-300"
            }`}
          />
        </button>
      </div>

      <button
        className={`button-rp-admin text-white ${
          isApproved ? "bg-gray-400" : "bg-green-500 cursor-pointer"
        }`}
        onClick={() => handleClickPublish()}
        disabled={isApproved ? true : false}
      >
        Publish
      </button>
      <button
        className={`button-rp-admin text-white ${
          isApproved ? "bg-yellow-500 cursor-pointer" : "bg-gray-400"
        }`}
        onClick={() => handleClickUnPublish()}
        disabled={isApproved ? false : true}
      >
        {" "}
        Unpublish
      </button>
      <button
        className="button-rp-admin text-white bg-red-500 cursor-pointer"
        onClick={() => handleClickDeletePhoto()}
      >
        {" "}
        Delete
      </button>
    </div>
  );
}

export default CondoItemLayout;
