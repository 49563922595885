import React, { FormHTMLAttributes, ReactNode } from "react";

interface RoomFormProps extends FormHTMLAttributes<HTMLFormElement> {
  children: ReactNode;
}

function Form({ children, ...props }: RoomFormProps) {
  return (
    <form {...props} className={"flex flex-col gap-4 " + " " + props.className}>
      {children}
    </form>
  );
}

export default Form;
