import React from "react";
import { Room } from "../../contexts/RoomContext";

interface RoomItemProps {
  room: Room;
}

function RoomItem({ room }: RoomItemProps) {
  return (
    <div className="grid  grid-cols-10 w-full justify-start items-center">
      <div className="col-span-2 break-words ">{room.Condo.name}</div>
      <div className="col-span-3">
        <span>{room.id}</span>
      </div>
      <div className="">
        <span>สถานะ: </span>
        <span className="text-yellow-500">{room.isApproved}</span>
      </div>
      <div>
        <span>{room.Owner.user.firstName} </span>
        <span>{room.Owner.user.lastName}</span>
      </div>
      <div>
        <span>{room.RoomInfo.roomNumber} </span>
        <span>ห้องนอน</span>
      </div>
      <div>
        <span>{room.RoomInfo.bathroomNumber}</span>
        <span> ห้องน้ำ</span>
      </div>
      <div>
        <span>คะแนน: </span>
        <span>{room.rating ? room.rating : 0}/10</span>
      </div>
    </div>
  );
}

export default RoomItem;
