import React, { InputHTMLAttributes } from "react";

interface CondoCheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  text: string;
}

function CondoCheckBox({ text, ...props }: CondoCheckBoxProps) {
  return (
    <span className=" flex items-center gap-2 ">
      <span className="font-medium">{text} : </span>
      <span className="bg-blue-300 flex items-center">
        <input type="checkbox" className={props.className} {...props} />
      </span>
    </span>
  );
}

export default CondoCheckBox;
