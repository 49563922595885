import React from "react";
import Button from "../../components/common/Button";
import ControllerContainer from "../../components/common/ControllerContainer";
import Item from "../../components/common/Item";
import ItemContainer from "../../components/common/ItemContainer";
import Search from "../../components/common/Search";
import Topic from "../../components/common/Topic";

function ReportDetailPage() {
  return (
    <div>
      <Topic topic="รายการปัญหาที่แจ้งเข้ามา" remaining={5} />
      <ControllerContainer>
        <Search className="search-box invisible " />
        <div className="flex gap-4">
          <Button text="ทั้งหมด" className="button-rp-admin text-red-500" />
        </div>
      </ControllerContainer>
      <ItemContainer>
            {/* {rooms
              ? rooms.map((el) => (
                  <Item active={true} id={el.id}>
                    <RoomItem room={el} />
                  </Item>
                ))
              : ""} */}
          </ItemContainer>
    </div>
  );
}

export default ReportDetailPage;
