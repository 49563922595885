import React, { FormEvent, useEffect, useState } from "react";
import Spinner from "../../components/common/Spinner";
import { useUser } from "../../contexts/UserContext";
import Topic from "../../components/common/Topic";
import { useParams } from "react-router-dom";
import Input from "../../components/common/Input";
import Button from "../../components/common/Button";
import Form from "../../components/common/Form";

function UserDetailsPage() {
  const {
    loading,
    userDetail,
    fetchUserDetail,
    updateUserCredit,
    updateBrokerListLimit,
  } = useUser();
  const { userId } = useParams();

  const [credits, setCredits] = useState(0);

  useEffect(() => {
    if (userId) {
      fetchUserDetail(userId);
    }
  }, []);

  const onClickAddCredit = (e: FormEvent) => {
    e.preventDefault();
    if (userDetail) {
      updateUserCredit(userDetail.id, credits);
    }
  };

  const onClickUpdateBrokerListLimit = (e: FormEvent, command: string) => {
    e.preventDefault();

    updateBrokerListLimit(userDetail?.Broker.id, command, userId);
  };

  return (
    <>
      <Spinner loading={loading} />
      {userDetail && (
        <div>
          <Topic topic={`ผู้ใช้งาน id: ${userId} `} />
          <Form>
            <div>
              <span className="font-medium">Email : </span>
              <span>{userDetail.email}</span>
            </div>
            <div>
              <span className="font-medium">ยืนยันตัวตน : </span>
              <span>{userDetail.isAuthenticated + ""}</span>
            </div>
            <div>
              <span className="font-medium">ชื่อ : </span>
              <span>{userDetail.firstName}</span>
            </div>
            <div>
              <span className="font-medium">นามสกุล : </span>
              <span>{userDetail.lastName}</span>
            </div>
            <div>
              <span className="font-medium">เครดิตคงเหลือ : </span>
              <span>{userDetail.credits}</span>
            </div>
            <div>
              <Input
                className="border rounded-md h-11 mr-4 outline-none"
                onChange={(e) => setCredits(Number(e.target.value))}
              />
              <Button text="เพิ่ม" onClick={(e) => onClickAddCredit(e)} />
            </div>
            <div>
              <span className="font-medium">เป็น Broker : </span>
              <span>{userDetail?.Broker ? "ใช่" : "ไม่ใช่"}</span>
            </div>
            <div>
              <span className="font-medium">
                จำนวนทรัพย์สูงสุดของ Broker :{" "}
              </span>
              <span>
                {userDetail?.Broker ? userDetail.Broker.listLimit : "-"}
              </span>
            </div>
            {userDetail?.Broker && (
              <div>
                <span className="mr-2">
                  <Button
                    text="เพิ่ม"
                    onClick={(e) => onClickUpdateBrokerListLimit(e, "add")}
                    className="bg-green-500 text-white"
                  />
                </span>
                <span>
                  <Button
                    text="ลด"
                    onClick={(e) => onClickUpdateBrokerListLimit(e, "minus")}
                    className="bg-red-500 text-white"
                  />
                </span>
              </div>
            )}
            <div>
              <span className="font-medium">เป็น Owner : </span>
              <span>{userDetail?.Owner ? "ใช่" : "ไม่ใช่"}</span>
            </div>
          </Form>
        </div>
      )}
    </>
  );
}

export default UserDetailsPage;
