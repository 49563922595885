import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Button from "../../components/common/Button";
import GoogleAutoComplete from "../../components/common/GoogleAutoComplete";
import Input from "../../components/common/Input";
import ItemContainer from "../../components/common/ItemContainer";
import List from "../../components/common/List";
import Spinner from "../../components/common/Spinner";
import Topic from "../../components/common/Topic";
import CondoCheckBox from "../../components/condo/CondoCheckBox";
import CondoImageItem from "../../components/condo/CondoImageItem";
import CondoItemLayout from "../../components/condo/CondoItemLayout";
import { useCondo } from "../../contexts/CondoContext";
import checkNumber from "../../function/checkNumber";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function CondoDetailsPage() {
  const ref = useRef<HTMLInputElement>(null);

  const [startDate, setStartDate] = useState<Date | null>(new Date());

  const { condoId } = useParams();

  const location = useLocation();

  const pathArray = location.pathname.split("/");

  const targetCondoId = pathArray[pathArray.length - 1];

  const {
    loading,
    condoDetail,
    googlePlace,
    getCondoDetail,
    handleOnChange,
    handleSaveCondo,
    handleUploadImages,
    numberOfActivePhotos,
    updateCondoAdsExpiry,
  } = useCondo();

  // console.log({ condoDetail });

  useEffect(() => {
    getCondoDetail(targetCondoId);
  }, []);

  useEffect(() => {
    if (condoDetail && condoDetail.adsExpiry) {
      setStartDate(new Date(condoDetail.adsExpiry));
    }
  }, [condoDetail]);

  const handleClickUpload = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  const handleUpdateAdsExpiry = async (e: React.FormEvent) => {
    e.preventDefault();

    if (condoId && startDate) {
      await updateCondoAdsExpiry(condoId, startDate);
    }
  };

  return (
    <>
      {condoDetail && (
        <div>
          <input
            type="file"
            ref={ref}
            className="hidden"
            multiple
            onChange={(e) => handleUploadImages(targetCondoId, e)}
          />
          <Topic topic={"คอนโด " + condoDetail.name} />
          <div className="flex gap-3 justify-end mb-5">
            <Button
              text="เพิ่มรูป"
              className="bg-blue-400 text-white"
              onClick={() => handleClickUpload()}
            />
            <Button
              text="บันทึกข้อมูล"
              className="bg-yellow-400 text-white"
              onClick={() => handleSaveCondo(targetCondoId)}
            />
            <Button text="เผยแพร่" className="bg-green-500 text-white" />
          </div>
          <div className="flex flex-row gap-3">
            <div className="basis-1/2">
              <div className="flex flex-col  gap-2 mb-4 ">
                <span className="font-medium ">ที่อยู่:</span>
                <div className="w-full">
                  <GoogleAutoComplete className="input-box" />
                </div>
              </div>
              <div className="flex flex-col gap-3 ">
                <div>
                  <span className="font-medium">Google Place Id* : </span>
                  <span>
                    {googlePlace && googlePlace.place_id
                      ? googlePlace.place_id
                      : condoDetail.googlePlaceId
                      ? condoDetail.googlePlaceId
                      : ""}
                  </span>
                </div>
                <div>
                  <span className="font-medium">ชื่อบน Google : </span>
                  <span>
                    {googlePlace && googlePlace.name
                      ? googlePlace.name
                      : condoDetail.name
                      ? condoDetail.name
                      : ""}
                  </span>
                </div>
                <div>
                  <span className="font-medium">Latitude : </span>
                  <span>
                    {googlePlace &&
                    googlePlace.geometry &&
                    googlePlace.geometry.location.lat()
                      ? googlePlace.geometry.location.lat()
                      : condoDetail.latitude}
                  </span>
                </div>
                <div>
                  <span className="font-medium">longitude : </span>
                  <span>
                    {googlePlace &&
                    googlePlace.geometry &&
                    googlePlace.geometry.location.lng()
                      ? googlePlace.geometry.location.lng()
                      : condoDetail.longitude}
                  </span>
                </div>
                <div>
                  <span className="font-medium">สถานะ : </span>
                  <span>
                    {condoDetail.isApproved ? "ตรวจสอบแล้ว" : "รอดำเนินการ"}
                  </span>
                </div>
                <div className="w-full">
                  <span className="font-medium ">ชื่อไทย* : </span>
                  <Input
                    className="input-box"
                    value={condoDetail.nameTh ? condoDetail.nameTh : ""}
                    onChange={(e) => handleOnChange({ nameTh: e.target.value })}
                  />
                </div>
                <div className="w-full">
                  <span className="font-medium">ชื่ออังกฤษ* : </span>
                  <Input
                    className="input-box"
                    value={condoDetail.name ? condoDetail.name : ""}
                    onChange={(e) => handleOnChange({ name: e.target.value })}
                  />
                </div>
                <div className="w-full">
                  <span className="font-medium">ที่อยู่ภาษาไทย* : </span>
                  <Input
                    className="input-box w-11"
                    value={condoDetail.addressTh ? condoDetail.addressTh : ""}
                    onChange={(e) =>
                      handleOnChange({ addressTh: e.target.value })
                    }
                  />
                </div>
                <div className="w-full">
                  <span className="font-medium">ที่อยู่ภาษาอังกฤษ* : </span>
                  <Input
                    className="input-box"
                    value={condoDetail.address ? condoDetail.address : ""}
                    onChange={(e) =>
                      handleOnChange({ address: e.target.value })
                    }
                  />
                </div>
                <div className="w-full">
                  <span className="font-medium">เขต/อำเภอ ภาษาอังกฤษ* : </span>
                  <Input
                    className="input-box"
                    value={condoDetail.district ? condoDetail.district : ""}
                    onChange={(e) =>
                      handleOnChange({ district: e.target.value })
                    }
                  />
                </div>
                <div className="w-full">
                  <span className="font-medium">เขต/อำเภอ ภาษาไทย* : </span>
                  <Input
                    className="input-box"
                    value={condoDetail.districtTh ? condoDetail.districtTh : ""}
                    onChange={(e) =>
                      handleOnChange({ districtTh: e.target.value })
                    }
                  />
                </div>
                <div className="w-full">
                  <span className="font-medium">พื้นที่ : </span>
                  <Input
                    className="input-box"
                    value={condoDetail.area ? condoDetail.area : ""}
                    onChange={(e) =>
                      handleOnChange({ area: Number(e.target.value) })
                    }
                  />
                </div>
                <div className="w-full">
                  <span className="font-medium">ชั้น : </span>
                  <Input
                    className="input-box"
                    value={condoDetail.floor ? condoDetail.floor : ""}
                    onChange={(e) =>
                      handleOnChange(
                        isNaN(Number(e.target.value))
                          ? {}
                          : {
                              floor:
                                e.target.value === ""
                                  ? 0
                                  : Number(e.target.value),
                            }
                      )
                    }
                  />
                </div>
                <div className="w-full">
                  <span className="font-medium">ราคาห้องสตูดิโอ : </span>
                  <Input
                    className="input-box"
                    value={
                      !condoDetail.avgPrice
                        ? ""
                        : condoDetail.avgPrice?.b0
                        ? condoDetail.avgPrice?.b0
                        : ""
                    }
                    onChange={(e) =>
                      handleOnChange(
                        isNaN(Number(e.target.value))
                          ? {}
                          : {
                              avgPrice:
                                e.target.value === ""
                                  ? {}
                                  : !condoDetail.avgPrice
                                  ? { b0: Number(e.target.value) }
                                  : {
                                      ...condoDetail.avgPrice,
                                      b0: Number(e.target.value),
                                    },
                            }
                      )
                    }
                  />
                </div>
                <div className="w-full">
                  <span className="font-medium">ราคา 1 ห้องนอน : </span>
                  <Input
                    className="input-box"
                    value={
                      !condoDetail.avgPrice
                        ? ""
                        : condoDetail.avgPrice?.b1
                        ? condoDetail.avgPrice?.b1
                        : ""
                    }
                    onChange={(e) =>
                      handleOnChange(
                        isNaN(Number(e.target.value))
                          ? {}
                          : {
                              avgPrice:
                                e.target.value === ""
                                  ? {}
                                  : !condoDetail.avgPrice
                                  ? { b1: Number(e.target.value) }
                                  : {
                                      ...condoDetail.avgPrice,
                                      b1: Number(e.target.value),
                                    },
                            }
                      )
                    }
                  />
                </div>
                <div className="w-full">
                  <span className="font-medium">ราคา 2 ห้องนอน : </span>
                  <Input
                    className="input-box"
                    value={
                      !condoDetail.avgPrice
                        ? ""
                        : condoDetail.avgPrice?.b2
                        ? condoDetail.avgPrice?.b2
                        : ""
                    }
                    onChange={(e) =>
                      handleOnChange(
                        isNaN(Number(e.target.value))
                          ? {}
                          : {
                              avgPrice:
                                e.target.value === ""
                                  ? {}
                                  : !condoDetail.avgPrice
                                  ? { b2: Number(e.target.value) }
                                  : {
                                      ...condoDetail.avgPrice,
                                      b2: Number(e.target.value),
                                    },
                            }
                      )
                    }
                  />
                </div>
                <div className="w-full">
                  <span className="font-medium">ราคา 3 ห้องนอน : </span>
                  <Input
                    className="input-box"
                    value={
                      !condoDetail.avgPrice
                        ? ""
                        : condoDetail.avgPrice?.b3
                        ? condoDetail.avgPrice?.b3
                        : ""
                    }
                    onChange={(e) =>
                      handleOnChange(
                        isNaN(Number(e.target.value))
                          ? {}
                          : {
                              avgPrice:
                                e.target.value === ""
                                  ? {}
                                  : !condoDetail.avgPrice
                                  ? { b3: Number(e.target.value) }
                                  : {
                                      ...condoDetail.avgPrice,
                                      b3: Number(e.target.value),
                                    },
                            }
                      )
                    }
                  />
                </div>
                <div className="flex flex-wrap gap-8">
                  <CondoCheckBox
                    text="สระว่ายน้ำ"
                    onChange={(e) =>
                      handleOnChange({ suggestPool: e.target.checked })
                    }
                    checked={condoDetail.suggestPool}
                  />
                  <CondoCheckBox
                    text="ฟิตเนส"
                    checked={condoDetail.suggestGym}
                    onChange={(e) =>
                      handleOnChange({ suggestGym: e.target.checked })
                    }
                  />
                  <CondoCheckBox
                    text="เครื่องซักผ้าส่วนกลาง"
                    checked={condoDetail.suggestPublicLaundry}
                    onChange={(e) =>
                      handleOnChange({ suggestPublicLaundry: e.target.checked })
                    }
                  />
                </div>
                <div className="mt-4">
                  <div className="mb-4">
                    <span className="font-medium ">Advertise Expiry : </span>
                    <span>
                      {condoDetail?.adsExpiry
                        ? new Date(condoDetail.adsExpiry).toLocaleDateString(
                            "en-US",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )
                        : "-"}
                    </span>
                  </div>
                  <span>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      className="border h-9 rounded-md px-3 cursor-pointer inline "
                    />
                  </span>
                  <span>
                    <Button
                      text="อัพเดท"
                      className="mt-4 bg-red-500 text-white"
                      onClick={(e) => handleUpdateAdsExpiry(e)}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="basis-1/2">
              <ItemContainer>
                {condoDetail.CondoPhotos.map((el, idx) => (
                  <CondoImageItem key={el.id}>
                    <CondoItemLayout
                      value={el}
                      images={condoDetail.CondoPhotos}
                    />
                  </CondoImageItem>
                ))}
              </ItemContainer>
            </div>
          </div>
        </div>
      )}
      <Spinner loading={loading} />
    </>
  );
}

export default CondoDetailsPage;
